import SEO from "../components/seo"
//import { kebabCase } from "lodash"
//import Img from "gatsby-image"
//import { useIntl } from "gatsby-plugin-intl"
//import makeCarousel from "react-reveal/makeCarousel"
//import tw from "twin.macro"
//import styled from "@emotion/styled"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import Layout from "../components/layout"
import localize from "../components/localize"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Courses from "../components/courses"
import Testimonials from "../components/testimonials"
import InfoRows from "../components/infoRows"
import ItemsRows from "../components/itemsRows"
import Quote from "../components/quote"
import { useIntl } from "gatsby-plugin-intl"

export const query = graphql`
  {
    route: sanityPage(title: { eq: "Inicio" }) {
      ...PlugComponents
    }
  }
`

const Page = props => {
  const intl = useIntl()

  const { data, errors } = props

  if (errors) {
    return <Layout>error</Layout>
  }

  const page = data.page || data.route

  const content = (page.content || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case "hero":
          el = <Hero key={c._key} {...c} cta={<FormattedMessage id="see_course" />} />
          break
        case "infoRows":
          el = <InfoRows key={c._key} {...c} />
          break
        case "itemsRows":
          el = <ItemsRows key={c._key} {...c} />
          break
        case "quote":
          el = <Quote key={c._key} {...c} />
          break
        case "testimonials":
          el = <Testimonials key={c._key} {...c} />
          break
        case "coursesList":
          el = <Courses key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return (
    <Layout>
      <SEO lang={intl.locale} title="Inicio" />
      <div className="">{content}</div>
    </Layout>
  )
}
export default localize(Page)

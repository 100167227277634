import React from "react"
//import AwesomeSlider from "react-awesome-slider"
//import "react-awesome-slider/dist/styles.css"
import { ImQuotesRight } from "react-icons/im"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

function Testimoniales(props) {
  return (
    <section
      key={props._key}
      className="relative items-center justify-center pt-24 pb-32 mx-auto bg-indigo-900 hero-pattern-2 "
    >
      <div className="max-w-6xl px-12 mx-auto text-white sm:px-6 lg:px-8">
        <h2 className="mb-12 font-serif text-5xl text-left text-white ">
          <FormattedMessage id="cea_students" />
        </h2>
        <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          {props.content.map(({ title, description, image, _key }) => (
            <div key={_key} className="relative p-6 py-1">
              <ImQuotesRight className="absolute top-0 left-0 z-0 text-6xl text-gray-100 transform -translate-x-6 opacity-20" />
              <div className="mt-2 text-lg text-gray-100">{description}</div>

              <div className="font-sans text-base font-medium leading-6 text-gray-100">
                <span className="flex items-center mt-2">
                  <div className="">
                    <Img
                      fixed={image.asset.fixed}
                      className="border-4 border-indigo-400 rounded-full"
                    />
                  </div>
                  <span className="inline-block ml-3 text-lg font-bold truncate">
                    {title}
                  </span>
                </span>
              </div>
            </div>
          ))}
        </div>
        <h3 className="pt-24 text-2xl text-center text-white">
          <a
            href="https://www.instagram.com/centroestudiosajmatova/"
            target="_blank"
            className="font-serif border-b border-gray-50 hover:border-indigo-500"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="visit_instagram_testimonials" />
          </a>
        </h3>
      </div>
    </section>
  )
}

export default Testimoniales
